import {
  Form as AntForm,
  Button as AntButton,
  Select as AntSelect,
  DatePicker as AntDatePicker,
  Checkbox as AntCheckbox,
} from "antd";
import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
`;

export const Form = styled(AntForm)`
  margin-bottom: 25px;
  & > * {
    margin-top: 10px;
  }
`;

export const DatePicker = styled(AntDatePicker)`
  width: 100%;
`;

export const Select = styled(AntSelect)`
  width: 100%;
`;

export const Button = styled(AntButton)`
  width: 100%;
`;

export const Checkbox = styled(AntCheckbox)``;
