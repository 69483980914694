import axios from "axios";

const URL = "http://192.168.1.60:5200/plan-validation";

export function validateHealth(data) {
  return axios.post(`${URL}/health`, data);
}

export function validateDental(data) {
  return axios.post(`${URL}/dental`, data);
}
