import { Table, Button, Tag } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

export default function ValidatorTable({ validateList }) {
  const columns = [
    {
      title: "Empresa",
      dataIndex: "subsidiary",
      key: "subsidiary",
    },
    {
      title: "Período",
      dataIndex: "period",
      key: "period",
    },
    {
      title: "Operadora",
      dataIndex: "operator",
      key: "operator",
    },
    {
      title: "Ação",
      key: "action",
      dataIndex: "divergenceFile",
      render: (_, row) => {
        if (row.divergences.length > 0) {
          return (
            <Button
              href={row.file}
              icon={<DownloadOutlined />}
              type="primary"
            >
              Download planilha
            </Button>
          );
        } else {
          return <Tag color="green">NÃO HÁ DIVERGENCIAS</Tag>;
        }
      },
    },
  ];

  return <Table columns={columns} dataSource={validateList} />;
}
