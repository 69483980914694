import axios from "axios";

const URL = "https://pws.rhdigitalexe.com.br/api/v2";

export function getSubsidiaries(query = "") {
  return axios.get(`${URL}/empresa?query=${query}`, {
    headers: { Authorization: `50802295-d783-4831-9ca3-9e10b1672caf` },
  });
}

export function getOperators(subsidiary, type = 1) {
  if (type === 1) {
    return axios.get(
      `${URL}/empresa/${subsidiary.substring(
        0,
        3
      )}/filial/${subsidiary.substring(3, 4)}/fornecedor_saude?ativo=true`,
      {
        headers: { Authorization: `50802295-d783-4831-9ca3-9e10b1672caf` },
      }
    );
  } else {
    return axios.get(
      `${URL}/empresa/${subsidiary.substring(
        0,
        3
      )}/filial/${subsidiary.substring(3, 4)}/fornecedor_odonto?ativo=true`,
      {
        headers: { Authorization: `50802295-d783-4831-9ca3-9e10b1672caf` },
      }
    );
  }
}
