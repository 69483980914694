import { useEffect, useState } from "react";
import { message, Spin, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import "antd/dist/antd.css";

import {
  Container,
  Form,
  DatePicker,
  Select,
  Button,
  Checkbox,
} from "./styles";
import ValidatorTable from "./components/ValidatorTable";
import { getSubsidiaries, getOperators } from "../../services/pws";
import { validateHealth, validateDental } from "../../services/pab";

function Default() {
  const { Option } = Select;

  const [loading, setLoading] = useState(0);
  const [form] = Form.useForm();
  const [subsidiaryOptions, setSubsidiaryOptions] = useState([]);
  const [operatorOptions, setOperatorOptions] = useState([]);
  const [files, setFiles] = useState([]);
  const [coparticipationFiles, setCoparticipationFiles] = useState([]);
  const [validateList, setValidateList] = useState([]);

  const validateFiles = (values) => {
    setLoading((l) => l + 1);

    const formData = new FormData();
    formData.append(
      "period",
      moment(values.period, "YYYY-MM").format("YYYYMM")
    );
    formData.append("company", values.subsidiary.substr(0, 3));
    formData.append("subsidiary", values.subsidiary.substr(3, 4));
    formData.append("operatorCode", parseInt(values.operator));
    formData.append("consolidate", values.ignoreSubsidiary ?? false);

    if (values?.files?.fileList?.length > 0) {
      values.files.fileList.forEach((file) => {
        formData.append("file", file.originFileObj);
      });
    }

    if (values?.coparticipationFiles?.fileList?.length > 0) {
      values.coparticipationFiles.fileList.forEach((file) => {
        formData.append("copartFile", file.originFileObj);
      });
    }

    if (values.validationType === 1) {
      validateHealth(formData)
        .then((response) => {
          setValidateList((validateList) =>
            validateList.concat({
              period: moment(values.period, "YYYY-MM").format("YYYYMM"),
              subsidiary: values.subsidiary,
              validationType: values.validationType,
              operator: values.operator,
              ignoreSubsidiary: values.ignoreSubsidiary,
              divergences: response?.data?.data?.divergences ?? [],
              file: response?.data?.data?.analyticFile,
            })
          );

          form.resetFields();
          setFiles([]);
          setCoparticipationFiles([]);
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data);
          } else if (err.request) {
            message.error(err.request);
          } else {
            message.error(
              err.message ?? "Ocorreu um erro ao tentar solicitar a validação."
            );
          }
        })
        .finally(() => {
          setLoading((l) => l - 1);
        });
    } else {
      validateDental(formData)
        .then((response) => {
          setValidateList((validateList) =>
            validateList.concat({
              period: moment(values.period, "YYYY-MM").format("YYYYMM"),
              subsidiary: values.subsidiary,
              validationType: values.validationType,
              operator: values.operator,
              ignoreSubsidiary: values.ignoreSubsidiary,
              divergences: response?.data?.data?.divergences ?? [],
              file: response?.data?.data?.analyticFile,
            })
          );

          form.resetFields();
          setFiles([]);
          setCoparticipationFiles([]);
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data);
          } else if (err.request) {
            message.error(err.request);
          } else {
            message.error(
              err.message ?? "Ocorreu um erro ao tentar solicitar a validação."
            );
          }
        })
        .finally(() => {
          setLoading((l) => l - 1);
        });
    }
  };

  const searchSubsidiaries = (query = "") => {
    setLoading((l) => l + 1);
    getSubsidiaries(query)
      .then((response) => {
        setSubsidiaryOptions(
          response.data.data.map((subsidiary) => {
            return {
              label: `${subsidiary.APS_COD_EMPRESA}${subsidiary.APS_COD_FILIAL} - ${subsidiary.APS_DESC_EMPRESA} - ${subsidiary.APS_DESC_FILIAL}`,
              value: `${subsidiary.APS_COD_EMPRESA}${subsidiary.APS_COD_FILIAL}`,
            };
          })
        );
      })
      .finally(() => {
        setLoading((l) => l - 1);
      });
  };

  useEffect(() => {
    searchSubsidiaries();
  }, []);

  const handleValuesChange = (_, allValues) => {
    if (allValues.subsidiary && allValues.validationType) {
      setLoading((l) => l + 1);
      getOperators(allValues.subsidiary, allValues.validationType)
        .then((response) => {
          setOperatorOptions(
            response.data.data.map((operator) => {
              return {
                label: `${operator.COD} - ${operator.CONTEUDO}`,
                value: operator.COD,
              };
            })
          );
        })
        .finally(() => {
          setLoading((l) => l - 1);
        });
    }
  };

  return (
    <Container>
      <Spin tip="Carregando..." spinning={loading > 0}>
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="vertical"
          onFinish={validateFiles}
          onValuesChange={handleValuesChange}
          initialValues={{
            period: "",
            company: "",
            subsidiary: "",
            operator: "",
          }}
        >
          <Form.Item
            required
            label="Período"
            name="period"
            rules={[
              { required: true, message: "É necessário selecionar o periodo." },
            ]}
          >
            <DatePicker picker="month" placeholder="Selecione um mês" />
          </Form.Item>
          <Form.Item
            required
            label="Filial"
            name="subsidiary"
            rules={[
              { required: true, message: "É necessário selecionar a filial." },
            ]}
          >
            <Select
              showSearch
              placeholder="Selecione a filial"
              defaultActiveFirstOption={false}
              filterOption={false}
              notFoundContent={null}
              onSearch={(value) => {
                searchSubsidiaries(value);
              }}
            >
              {subsidiaryOptions.map((option) => {
                return (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="ignoreSubsidiary" valuePropName="checked">
            <Checkbox>Consolidar</Checkbox>
          </Form.Item>
          <Form.Item
            required
            label="Tipo de validação"
            name="validationType"
            rules={[
              {
                required: true,
                message: "É necessário selecionar o tipo do arquivo.",
              },
            ]}
          >
            <Select placeholder="Selecione o tipo do arquivo">
              <Option value={1}>1 - Saúde</Option>
              <Option value={2}>2 - Odonto</Option>
            </Select>
          </Form.Item>
          <Form.Item
            required
            label="Selecione a operadora"
            name="operator"
            rules={[
              {
                required: true,
                message: "É necessário selecionar a operadora.",
              },
            ]}
          >
            <Select placeholder="Operadora">
              {operatorOptions.map((option) => {
                return (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "É necessário selecionar anexar a planilha.",
              },
            ]}
            name="files"
          >
            <Upload
              multiple
              fileList={files}
              onChange={({ fileList }) => {
                setFiles(fileList);
              }}
              beforeUpload={() => {
                return false;
              }}
            >
              <Button icon={<UploadOutlined />}>
                Adicionar arquivos de mensalidade
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: false,
              },
            ]}
            name="coparticipationFiles"
          >
            <Upload
              multiple
              fileList={coparticipationFiles}
              onChange={({ fileList }) => {
                setCoparticipationFiles(fileList);
              }}
              beforeUpload={() => {
                return false;
              }}
            >
              <Button icon={<UploadOutlined />}>
                Adicionar arquivos de coparticipação
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Processar
            </Button>
          </Form.Item>
        </Form>
        <div>
          <ValidatorTable validateList={validateList} />
        </div>
      </Spin>
    </Container>
  );
}

export default Default;
